import React, { useEffect, useState } from "react";
import { login, logout, isAuthenticated, getProfile } from "../utils/auth";
import { navigate } from "gatsby-link";
import { Spinner } from "theme-ui";
import "./../components/style.css";

const Monthly = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		//update user credentials
		updateUser();
	}, [loading]);

	//check if user is authenticated
	if (!isAuthenticated()) {
		login();
		return <p>Redirecting to login...</p>;
	}
	//retrieves the data from the user
	const user = getProfile();

	/* This function will be in charge of updating the user who successfully paid for ame systems
	subscription. It will also define the start date of the subscription and the end date of the 
	subscription. This will update the user infromation in case the subscription expires. It will 
	update the information with the data of the new subscription. */
	const updateUser = () => {
		//Variable declarations
		const subcriptionStartDate = new Date();
		const subscriptionExpiredDate = new Date();
		const expirationDateMonth = subscriptionExpiredDate.getMonth() + 1;
		subscriptionExpiredDate.setMonth(expirationDateMonth);
		const userID = user.sub;

		//send the post request to Auth0 management v2 API
		fetch(`https://ame-systems.us.auth0.com/api/v2/users/${userID}`, {
			method: "PATCH",
			headers: {
				"Content-type": "application/json",
				authorization: `Bearer ${process.env.AUTH0_APIV2KEY}`,
			},
			body: JSON.stringify({
				user_metadata: {
					validToken: true,
					subs_start_date: subcriptionStartDate,
					subs_expire_date: subscriptionExpiredDate,
				},
			}),
		}).then(() => {
			setTimeout(() => {
				console.log("proccessing.. ");
				return navigate("/");
			}, 2000);
		});
	};

	if (loading) {
		return <Spinner />;
	}
};

export default Monthly;
